import React from 'react';
import PropTypes from 'prop-types';

/**
 * Enhances a component with an onClick which sends analytics event
 * @param {React.Component} WrappedComponent
 */
export default function withAnalyticsOutboundClick(WrappedComponent) {
  function WithAnalyticsOutboundClick({ onClick, href, ...rest }) {
    return (
      <WrappedComponent
        onClick={getCombinedOnClick({ onClick, href })}
        {...rest}
        href={href}
      />
    );
  }

  WithAnalyticsOutboundClick.propTypes = {
    onClick: PropTypes.func,
    href: PropTypes.string,
  };

  return WithAnalyticsOutboundClick;
}

/**
 * Get an onClick handler which combines the original onClick and sending the analytics event
 */
function getCombinedOnClick({ onClick, href }) {
  return function(event) {
    sendAnalyticsEvent(href);
    if (onClick && typeof onClick === 'function') {
      return onClick(event);
    }
    return false;
  };
}

function sendAnalyticsEvent(url) {
  if (window.gtag) {
    window.gtag('event', 'click', {
      event_category: 'outbound',
      event_label: url,
      transport_type: 'beacon',
      event_callback: function() {},
    });
  }
}
