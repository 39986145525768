import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import {
  faApple,
  faLinux,
  faWindows,
} from '@fortawesome/free-brands-svg-icons';
import { faBrowser } from '@fortawesome/pro-solid-svg-icons';
import tu from '../lib/themeUtils';
import { Layout, Seo } from '../components/compounds';
import {
  Section,
  Container,
  Title,
  Button,
  FaSvg,
  Paragraph,
  Link,
  List,
  Code,
} from '../components/blocks';
import { withAnalyticsOutboundClick } from '../components/hoc';
const { getTheme } = tu;

const INSTALL_URLS = {
  windows:
    'https://yesod-rc.s3.amazonaws.com/Zen-Protocol-Wallet-Yesod+Setup+1.0.0.exe',
  mac: 'https://yesod-rc.s3.amazonaws.com/Zen-Protocol-Wallet-Yesod-1.0.0.dmg',
  linux: 'https://yesod-rc.s3.amazonaws.com/zen-wallet-yesod-1.0.0.tar.gz',
};

const OutboundLink = withAnalyticsOutboundClick(Link);
const DownloadBtn = withAnalyticsOutboundClick(styled(Button)`
  margin-right: ${tu.spacings.sm};
  margin-bottom: ${tu.spacings.sm};
  span {
    margin-left: 5px;
  }
`);

const StyledLabel = styled.div`
  font-family: industry;
  font-size: ${tu.fontSizes.sm};
  font-weight: 700;
  letter-spacing: 3px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: ${tu.spacings.sm};
  color: ${p => getTheme(p).colors.title};
`;
const StyledSubLabel = styled(StyledLabel)`
  margin-bottom: ${tu.spacings.xs};
  font-size: ${tu.fontSizes.sm.replace('rem', '') - 0.05}rem;
  text-transform: none;
`;

const StyledList = styled(List)`
  margin-top: 0;
`;

const WrapperDiv = Paragraph.withComponent('div');

const DownloadPage = () => (
  <Layout>
    <Seo title="Download" />
    <Section>
      <Container>
        <Title>INSTALL INSTRUCTIONS</Title>
        <Paragraph>
          These prerequisites are for users who have not installed the Zen
          Protocol Wallet.
        </Paragraph>
        <WrapperDiv>
          <StyledLabel>WINDOWS</StyledLabel>
          <StyledList ordered>
            <li>
              Install{' '}
              <Link
                target="_blank"
                href="https://www.microsoft.com/en-us/download/details.aspx?id=55167"
              >
                .NET Framework 4.7
              </Link>
            </li>
            <li>
              Install{' '}
              <Link
                target="_blank"
                href="https://github.com/zenprotocol/zen-prerequisites-nuget/raw/master/vc_redist.x64.exe"
              >
                vc++ redist 2015 x64
              </Link>
            </li>

            <li>
              Download the{' '}
              <OutboundLink target="_blank" href={INSTALL_URLS.windows}>
                Yesod-RC
              </OutboundLink>
            </li>

            <li>
              Your browser may warn that the download is not commonly
              downloaded. Click &quot;keep&quot; to download it anyway.
            </li>
            <li>
              Run the installer. You&apos;ll need to click the More Info button
              and click Run anyway
            </li>
          </StyledList>
          <StyledLabel>MAC OSX</StyledLabel>
          <StyledList ordered>
            <li>
              Install{' '}
              <Link
                target="_blank"
                href="http://www.mono-project.com/download/stable/#download-mac"
              >
                mono
              </Link>
              . If you choose to install via a package manager, add Mono&apos;s
              own repository first.
            </li>
            <li>
              Download the{' '}
              <OutboundLink target="_blank" href={INSTALL_URLS.mac}>
                Yesod-RC
              </OutboundLink>
            </li>
            <li>
              After installing drag the Zen-Protocol-Wallet-Yesod icon to the
              applications folder
            </li>
            <li>Right click and choose &apos;open&apos;</li>
            <li>Confirm by clicking the &apos;open&apos; button</li>
          </StyledList>
          <StyledLabel>LINUX</StyledLabel>
          <StyledList ordered>
            <li>
              Install{' '}
              <Link
                target="_blank"
                href="https://www.mono-project.com/download/stable/#download-lin-ubuntu"
              >
                mono-devel
              </Link>
              . If you choose to install via a package manager, add Mono&apos;s
              own repository first.
            </li>
            <li>
              Install lmdb. The package name is liblmdb0 on Ubuntu and lmdb on
              Fedora. sudo apt install {'<'}package-name{'>'}
            </li>
            <li>
              Download the{' '}
              <OutboundLink target="_blank" href={INSTALL_URLS.linux}>
                Yesod-RC
              </OutboundLink>
            </li>
            <li>Once extracted you can run the executable named zen-wallet.</li>
          </StyledList>
        </WrapperDiv>

        <div>
          <StyledLabel>DOWNLOAD YESOD WALLET</StyledLabel>
          <DownloadBtn target="_blank" href={INSTALL_URLS.windows}>
            <FaSvg icon={faWindows} /> <span>Windows</span>
          </DownloadBtn>
          <DownloadBtn target="_blank" href={INSTALL_URLS.mac}>
            <FaSvg icon={faApple} /> <span>Mac OSX</span>
          </DownloadBtn>
          <DownloadBtn target="_blank" href={INSTALL_URLS.linux}>
            <FaSvg icon={faLinux} /> <span>Linux</span>
          </DownloadBtn>
          <DownloadBtn
            target="_blank"
            href="https://wallet.blockchaindevelopmentltd.com/"
          >
            <FaSvg icon={faBrowser} /> <span>Web wallet</span>
          </DownloadBtn>
        </div>
        <br />
        <br />
        <WrapperDiv>
          <StyledLabel>INSTALL FROM NPM:</StyledLabel>
          <StyledSubLabel>
            Install{' '}
            <OutboundLink
              target="_blank"
              href="https://nodejs.org/en/download/"
            >
              Nodejs
            </OutboundLink>
          </StyledSubLabel>

          <StyledList>
            <li>
              Recommended to install using{' '}
              <OutboundLink
                target="_blank"
                href="https://github.com/creationix/nvm#installation"
              >
                NVM
              </OutboundLink>
            </li>
            <li>
              Recommended to install Node LTS <Code>nvm install 8.9.4</Code>
            </li>
          </StyledList>

          <StyledSubLabel>
            Point your npm directory to our repository
          </StyledSubLabel>
          <Paragraph>
            Run the following commands in the Terminal / Command Prompt:
            <br />
            <Code>
              npm config set @bdl:registry
              https://www.myget.org/F/blockchaindevelopment/npm/
            </Code>
          </Paragraph>
          <StyledSubLabel>Install from Npm</StyledSubLabel>
          <Paragraph>
            <Code>npm install @bdl/zen-node-yesod -g</Code>
          </Paragraph>

          <StyledSubLabel>Running the wallet + full node</StyledSubLabel>
          <Paragraph>
            Run <Code>zen-node --test</Code> from anywhere in your command line
            (terminal) to start up the wallet
          </Paragraph>
          <StyledLabel>RUNNING WITH ARGUMENTS</StyledLabel>
          <StyledList>
            <li>
              Wipe (Clear Data)
              <br />
              When we launch a new testnet sometimes you will need to wipe your
              local database in order to sync. <br />
              Note that this will NOT delete any data/tokens from the wallet.
              You can do this by running: <Code>zen-node --test --wipe</Code>
            </li>
            <li>
              Full Wipe (Clear Data and wallet)
              <br />
              To completely wipe the blockchain + the wallet from your computer
              run: <Code>zen-node --test --wipe full</Code>
            </li>
            <li>
              Miner
              <br />
              Run the node with a miner. You can also choose the amount of
              threads you want to utilize.
              <br />
              <Code>zen-node --test --miner 4</Code>
            </li>
          </StyledList>
          <StyledLabel>INSTALL LINKS:</StyledLabel>
          <StyledList>
            <li>
              <OutboundLink
                target="_blank"
                href="https://gitlab.com/blockchaindevelopment/zenprotocol/-/tree/cgminer-rc1"
              >
                Build from CGMiner
              </OutboundLink>
            </li>
            <li>
              <OutboundLink
                target="_blank"
                href="https://gitlab.com/blockchaindevelopment/zenprotocol/-/tree/release-candidate-network"
              >
                Build from release
              </OutboundLink>
            </li>
          </StyledList>
          <StyledLabel>INSTALL INSTRUCTIONS FOR BUILD FROM SOURCE</StyledLabel>
          <StyledSubLabel>Linux</StyledSubLabel>
          <StyledList ordered>
            <li>
              Install mono-devel from http://www.mono-project.com/download.{' '}
              <br />
              If you choose to install via a package manager, add Mono&apos;s
              own repository first.
            </li>
            <li>
              Install lmdb. The package name is liblmdb0 on Ubuntu and lmdb on
              Fedora.
            </li>
            <li>
              Run the following:
              <StyledList>
                <li>
                  <Code>./paket restore</Code>
                </li>
                <li>
                  <Code>msbuild src</Code>
                </li>
              </StyledList>
            </li>
          </StyledList>
          <StyledSubLabel>Mac OSX</StyledSubLabel>
          <StyledList ordered>
            <li>Install Mono as in step 1 of the instructions for Linux.</li>
            <li>
              {' '}
              Install lmdb. You can get it via brew with brew install lmdb.
            </li>
            <li>
              Run the following:
              <StyledList>
                <li>
                  <Code>./paket restore</Code>
                </li>
                <li>
                  <Code>msbuild src</Code>
                </li>
              </StyledList>
            </li>
          </StyledList>
          <StyledSubLabel>Windows</StyledSubLabel>
          <Paragraph>Windows is not yet supported</Paragraph>
          <StyledLabel>RUN THE NODE</StyledLabel>
          <StyledSubLabel>Linux and OSX</StyledSubLabel>
          <StyledList>
            <li>
              <Code>cd src/Node/bin/Debug</Code>
            </li>
            <li>
              <Code>./zen-node --test</Code>
            </li>
          </StyledList>
          <StyledLabel>CLI</StyledLabel>
          <Paragraph
            css={css`
              margin-bottom: ${tu.spacings.xs};
            `}
          >
            You can communicate with the node with zen-cli.
            <br />
            Enter the bin directory of the node (src/Node/bin/Debug) and run:
          </Paragraph>
          <StyledSubLabel>Linux and OSX</StyledSubLabel>
          <StyledList>
            <li>
              <Code>./zen-cli --help</Code>
            </li>
          </StyledList>
        </WrapperDiv>
      </Container>
    </Section>
  </Layout>
);

export default DownloadPage;
